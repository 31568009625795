import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PacService, { Pac } from 'lib/api/pacApi';
import { Form, Input, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import Logo from 'components/Logo';
import { showErrorResponseNotification } from 'lib/helpers';
import privateKeysService from 'lib/api/privateKeysApi';
import { OnboardingStorageService } from 'lib/services/onboardingStorageService';
import { ApiKeyType } from 'lib/enums/apiKey';
import { IOnboardingStep1 } from 'lib/interfaces/onboarding';
import Button from 'components/Button/Button';
import Spin from 'components/Spin';
import PreferenceCenter from 'assets/PreferenceCenter.svg';
import PreferenceCenterHover1 from 'assets/PreferenceCenterHover1.svg';
import PreferenceCenterHover2 from 'assets/PreferenceCenterHover2.svg';
import PreferenceCenterHover3 from 'assets/PreferenceCenterHover3.svg';
import PreferenceCenterHover4 from 'assets/PreferenceCenterHover4.svg';
import PreferenceCenterHover5 from 'assets/PreferenceCenterHover5.svg';
import { Tooltip as TooltipIcon } from 'components/Icons';
import { StyledQuill } from 'pages/PAC/styled';

import { hexCode, isNotEmptyField } from 'lib/regex/validations';
import { Tooltip } from 'components/Form';

import { StyledColorPicker } from 'pages/PAC/Styling/styled';
import theme from 'lib/theme';
import {
  StyledLogo,
  Wrapper,
  Sidebar,
  TopButtons,
  StyledSwitch,
  StyledText,
  FlexWrapper,
  FormWrapper,
  ExampleTitle,
  ExampleContainer,
  ExampleImage,
  OnboardingContent,
  ScrollableContainer,
  OnboardingSidebarTitle,
  LineDivider,
  IconHighlight,
} from './styled';
import { HeaderSection } from './HeaderSection';

const { Title } = Typography;

const Step1 = () => {
  const [form] = Form.useForm();
  const { user } = useAuth0();
  const [isDataLoading, setLoading] = useState<boolean>(true);
  const [hoverImageActive, setHoverImageActive] = useState<null | number>();
  const navigate = useNavigate();

  const data = OnboardingStorageService.getStep1Data();

  const checkPac = async () => {
    try {
      const response = await PacService.get({
        search: user?.email,
      });
      const pacsThatCustomerOwns = response.data.results;
      const pacLite = pacsThatCustomerOwns.find((el: Pac) => el.type === 'lite');

      if (pacLite) {
        try {
          const apiKeys = await privateKeysService.get();
          OnboardingStorageService.setOnboardingResults({
            widgetUuid: pacLite?.widgetUuids[0],
            pacUuid: pacLite.uuid,
            publicApiKey:
              apiKeys.data.apiKeys?.find((k) => k.type === ApiKeyType.PUBLIC)?.key || '',
            optionUuid: pacLite?.widgets[0]?.topics[0]?.options[0].uuid,
          });
          navigate('/onboarding/step-5');
        } catch (error) {
          showErrorResponseNotification(error);
        }
      }
    } catch (error) {
      showErrorResponseNotification(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkPac();
  }, []);

  const onFinish = (values: IOnboardingStep1) => {
    OnboardingStorageService.setStep1Data({
      ...values,
      logoUrl: values.logoUrl === '' ? undefined : values.logoUrl,
    });
    navigate('/onboarding/step-3');
  };

  const buildPreviewImage = () => {
    switch (hoverImageActive) {
      case 1:
        return <img style={{ width: '100%' }} src={PreferenceCenterHover1} alt="Example" />;
      case 2:
        return <img style={{ width: '100%' }} src={PreferenceCenterHover2} alt="Example" />;
      case 3:
        return <img style={{ width: '100%' }} src={PreferenceCenterHover3} alt="Example" />;
      case 4:
        return <img style={{ width: '100%' }} src={PreferenceCenterHover4} alt="Example" />;
      case 5:
        return <img style={{ width: '100%' }} src={PreferenceCenterHover5} alt="Example" />;
      default:
        return <img style={{ width: '100%' }} src={PreferenceCenter} alt="Example" />;
    }
  };

  return (
    <Wrapper>
      <Sidebar>
        <StyledLogo>
          <Logo />
        </StyledLogo>
        <div>
          <StyledText>Preference Manager Audience Unlocker</StyledText>
          <OnboardingSidebarTitle level={1}>Create your Preference Center</OnboardingSidebarTitle>
          <span>
            Get started by configuring the dedicated interface to allow your users to view and
            manage their privacy preference settings.
          </span>
        </div>
      </Sidebar>
      {isDataLoading ? (
        <OnboardingContent>
          <Spin />
        </OnboardingContent>
      ) : (
        <OnboardingContent>
          <HeaderSection activeStepIndex={1} />
          <Title level={2}>Configure</Title>
          <Title level={4} style={{ margin: 0, color: '#595959', fontWeight: 400 }}>
            Add your configuration details here to customize your Preference Center.
          </Title>
          <ScrollableContainer style={{ marginTop: '20px', paddingBottom: '20px' }}>
            <FlexWrapper>
              <ExampleContainer>
                <ExampleTitle>Preference Center preview</ExampleTitle>
                <ExampleImage style={{ padding: '20px', paddingTop: 0, paddingBottom: 0 }}>
                  {buildPreviewImage()}
                </ExampleImage>
              </ExampleContainer>
              <div style={{ flex: 1 }}>
                <FormWrapper style={{ overflowX: 'auto' }}>
                  <Form
                    style={{ width: '100%' }}
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={
                      data || {
                        title: '',
                        description: '',
                        widgetTitle: '',
                        widgetDescription: '',
                        logoUrl: '',
                        doubleOptIn: false,
                        baseColor: theme.colors.primary,
                      }
                    }
                  >
                    <Form.Item
                      name="title"
                      label="Preference Center headline"
                      rules={[{ required: true, message: 'Please enter a title' }, isNotEmptyField]}
                      tooltip={{
                        icon: (
                          <IconHighlight
                            onMouseOver={() => setHoverImageActive(1)}
                            onMouseLeave={() => setHoverImageActive(null)}
                          >
                            <TooltipIcon />
                          </IconHighlight>
                        ),
                      }}
                      data-cy="input-preferenceCenterTitle"
                    >
                      <Input placeholder="My Preference Center" />
                    </Form.Item>
                    <Form.Item
                      name="description"
                      label="Preference Center welcome message"
                      data-cy="input-preferenceCenterDescription"
                      tooltip={{
                        icon: (
                          <IconHighlight
                            onMouseOver={() => setHoverImageActive(2)}
                            onMouseLeave={() => setHoverImageActive(null)}
                          >
                            <TooltipIcon />
                          </IconHighlight>
                        ),
                      }}
                    >
                      <StyledQuill
                        modules={{
                          toolbar: [['bold', 'italic', 'underline'], ['link'], ['clean']],
                        }}
                        formats={['bold', 'italic', 'underline', 'strike', 'link']}
                        placeholder="Welcome to our Preference Center page..."
                        theme="snow"
                        style={{ fontSize: '14px' }}
                        data-cy="onboarding-pac-description-input"
                      />
                    </Form.Item>
                    <LineDivider style={{ marginTop: '28px', marginBottom: '18px' }} />
                    <Form.Item
                      name="widgetTitle"
                      label="Consent section headline"
                      tooltip={{
                        icon: (
                          <IconHighlight
                            onMouseOver={() => setHoverImageActive(3)}
                            onMouseLeave={() => setHoverImageActive(null)}
                          >
                            <TooltipIcon />
                          </IconHighlight>
                        ),
                      }}
                      rules={[
                        { required: true, message: 'Please enter consent section headline' },
                        isNotEmptyField,
                      ]}
                      data-cy="input-consentTitle"
                    >
                      <Input placeholder="Choose your privacy settings" />
                    </Form.Item>

                    <Form.Item
                      name="widgetDescription"
                      label="Consent section description text"
                      data-cy="input-ConsentSectionCenterDescription"
                      tooltip={{
                        icon: (
                          <IconHighlight
                            onMouseOver={() => setHoverImageActive(4)}
                            onMouseLeave={() => setHoverImageActive(null)}
                          >
                            <TooltipIcon />
                          </IconHighlight>
                        ),
                      }}
                    >
                      <StyledQuill
                        modules={{
                          toolbar: [['bold', 'italic', 'underline'], ['link'], ['clean']],
                        }}
                        formats={['bold', 'italic', 'underline', 'strike', 'link']}
                        placeholder="Here you can manage your consents’ settings"
                        theme="snow"
                        style={{ fontSize: '14px' }}
                        data-cy="onboarding-widget-description-input"
                      />
                    </Form.Item>
                    <LineDivider style={{ marginTop: '28px', marginBottom: '18px' }} />
                    <Form.Item
                      name="logoUrl"
                      label="URL to your Company logo"
                      data-cy="input-companyLogo"
                      tooltip={{
                        icon: (
                          <IconHighlight
                            onMouseOver={() => setHoverImageActive(5)}
                            onMouseLeave={() => setHoverImageActive(null)}
                          >
                            <TooltipIcon />
                          </IconHighlight>
                        ),
                      }}
                      rules={[
                        {
                          type: 'url',
                          message: 'This field must be a valid url.',
                        },
                      ]}
                    >
                      <Input placeholder="https://..." />
                    </Form.Item>
                    <LineDivider style={{ marginTop: '28px', marginBottom: '18px' }} />
                    <Form.Item
                      name="baseColor"
                      label="Base color"
                      validateTrigger="onBlur"
                      data-cy="input-baseColor"
                      rules={[hexCode]}
                    >
                      <StyledColorPicker
                        showText
                        disabledAlpha
                        onChange={(color) => {
                          form.setFieldValue('baseColor', color.toHexString());
                          return color.toHexString();
                        }}
                      />
                    </Form.Item>
                    <LineDivider style={{ marginTop: '28px', marginBottom: '18px' }} />

                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <span style={{ marginLeft: '10px' }}>
                        Activate Double-Opt-In{' '}
                        <Tooltip
                          title="You can activate the double opt-in scenario for your data, requiring users to confirm their data submissions via a confirmation email. If not confirmed, pending data will be deleted after 24 hours and will not be processed in connected marketing platforms."
                          placement="left"
                        />
                      </span>
                      <Form.Item
                        name="doubleOptIn"
                        valuePropName="checked"
                        data-cy="switch-doubleOptIn"
                        style={{ margin: 0 }}
                      >
                        <StyledSwitch
                          checked={form.getFieldValue('doubleOptIn')}
                          onChange={(value) => form.setFieldValue('doubleOptIn', value)}
                          data-cy="configuration-new-widget-double-opt-in"
                        />
                      </Form.Item>
                    </div>
                  </Form>
                </FormWrapper>
              </div>
            </FlexWrapper>
          </ScrollableContainer>
          <TopButtons style={{ justifyContent: 'right' }}>
            <Button
              type="black"
              size="large"
              htmlType="submit"
              data-cy="button-nextStep"
              onClick={form.submit}
            >
              Next
            </Button>
          </TopButtons>
        </OnboardingContent>
      )}
    </Wrapper>
  );
};

export default Step1;
