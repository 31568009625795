import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useMessage } from 'lib/hooks';
import Removed from 'assets/cancel.svg';
import Added from 'assets/accept.svg';
import Modal from 'components/Modal/Modal';
import { Title, TitleWrapper } from 'pages/PAC/styled';
import { StyledTable } from 'components/Table/styled';
import { TrashCan as TrashCanIcon, Export as ExportIcon } from 'components/Icons';
import Button from 'components/Button/Button';

import { IWidget, IHistory } from 'lib/interfaces/widget';
import { ChangeModeValues } from 'lib/enums/history';

import WidgetApiService from 'lib/api/widgetApi';
import CustomerService, { WidgetHistoryData } from 'lib/api/customerApi';
import { showErrorResponseNotification, exportToCsv } from 'lib/helpers';
import DeleteConfirmationModal from '../DeleteConfirmation';
import {
  StyledCollapse,
  Description,
  Dot,
  ChangelogWrapper,
  DeletionBox,
  OptionName,
  OptionContainer,
  ButtonsContainer,
  ExpiredLabel,
} from './styled';
import { DEFAULT_PAGINATION_TOTAL } from '../../../lib/constants/global';

const { Panel } = StyledCollapse;
export interface Answer {
  topicAnswerUuids: Array<string>;
  topicName: string;
  topicUuid: string;
}
interface IUserDetailsProps {
  onClose: () => void;
  preference: {
    answers: Array<Answer>;
    widgetUuid: string;
    uuid: string;
    userId: string;
    updatedAt: string;
    expiresAt?: string;
  };
}

const columns = [
  {
    title: 'Topic',
    dataIndex: 'topic',
    key: 'topic',
  },
  {
    title: 'Preferences',
    dataIndex: 'preferences',
    key: 'preferences',
    render: (preferences: string) => {
      return (
        <OptionContainer>
          {preferences &&
            preferences
              ?.split('|uniqSplitter|')
              .map((pref) => <OptionName key={pref}>{pref.trim()}</OptionName>)}
        </OptionContainer>
      );
    },
  },
];

const historyColumns = [
  {
    title: 'Registered Changes',
    dataIndex: 'changelog',
    key: 'changelog',
    width: '49%',
    render: (record: { text: string; mode: number }) => {
      return (
        <ChangelogWrapper>
          <img
            src={
              record.mode === ChangeModeValues.REMOVED ||
              record.mode === ChangeModeValues.EXPIRED ||
              record.mode === ChangeModeValues.REMOVED_ALL
                ? Removed
                : Added
            }
            alt="img"
          />
          <p>{record.text}</p>
        </ChangelogWrapper>
      );
    },
  },
  {
    title: 'Changed by',
    dataIndex: 'changedBy',
    key: 'changedBy',
    width: '20%',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
];

export const transformDate = (date: string) => {
  if (!date) {
    return 'No date';
  }
  return `${date.split('T').join(' ').slice(0, -5)} UTC`;
};

const UserDetailsModal = ({ onClose, preference }: IUserDetailsProps) => {
  const [userData, setUserData] = useState<Array<{ topic: string; preferences: string }>>([]);
  const [history, setHistory] = useState<Array<any>>([]);
  const [widget, setWidget] = useState<IWidget | null>(null);
  const [confirmDeletion, switchConfirmDeletion] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const message = useMessage();

  const fetchWidget = async () => {
    const response = await WidgetApiService.getById(preference.widgetUuid);
    setWidget(response.data);
  };

  const getTranslatedMessage = (historyPayload: IHistory, translatedString: string) => {
    return historyPayload?.language !== 'en'
      ? `([${historyPayload?.language.toLocaleUpperCase()}]: ${translatedString})`
      : '';
  };

  const fetchHistory = async () => {
    const historyData: AxiosResponse<{ payload: Array<WidgetHistoryData> }> =
      await CustomerService.getWidgetHistoryByWidgetId(preference.uuid, {
        widgetId: preference.widgetUuid,
      });

    const payloadHistory = historyData.data.payload.filter((record) => record.answers.length !== 0);

    if (payloadHistory.length > 0 && widget) {
      const parsedHistory = payloadHistory.map((update: WidgetHistoryData) => {
        const changelog = (
          <div>
            {update.changeMode === ChangeModeValues.EXPIRED ? (
              <span>
                Expired preferences under <b>{update?.topicTitleEn}</b>
              </span>
            ) : update.changeMode === ChangeModeValues.REMOVED_ALL ? (
              <span>
                Deleted preferences under <b>{update?.topicTitleEn}</b>
              </span>
            ) : (
              <>
                {update.changeMode === ChangeModeValues.REMOVED
                  ? 'Removed '
                  : update.changeMode === ChangeModeValues.RENEWED
                    ? 'Confirmed '
                    : 'Added '}{' '}
                choice under{' '}
                <b>
                  {update?.topicTitleEn} {getTranslatedMessage(update, update.topicTitleTranslated)}
                </b>
                :{' '}
                <OptionContainer>
                  {update.answers.map((answer) => {
                    return (
                      <OptionName key={answer.en}>
                        {`${answer.en}`} {getTranslatedMessage(update, answer.translated)}
                      </OptionName>
                    );
                  })}
                </OptionContainer>
              </>
            )}
          </div>
        );

        return {
          changelog: { text: changelog, mode: update.changeMode },
          changedBy: update.author,
          date: transformDate(update.timestamp.value),
        };
      });

      setHistory(parsedHistory);
    } else {
      // Logic to display first set of data in history if there are no changes made before
      const parsedHistory = preference.answers.map((answer: Answer) => {
        let changelog;
        if (!answer.topicAnswerUuids.length) {
          const selectedTopic = widget?.topics.find(
            (topic) => `${topic.uuid}:name` === answer.topicName,
          );
          changelog = (
            <>
              Added empty choice under <b>{selectedTopic?.name}</b>
            </>
          );
        } else {
          const selectedTopic = widget?.topics.find(
            (topic) => `${topic.uuid}:name` === answer.topicName,
          );
          const topicAnswerUuidsTranslate = answer.topicAnswerUuids.map(
            (topicAnswerUuid: string) => {
              return (
                selectedTopic?.options.find((option) => `${option.uuid}:name` === topicAnswerUuid)
                  ?.name || topicAnswerUuid
              );
            },
          );
          changelog = (
            <>
              Added choice under <b>{selectedTopic?.name}</b>:{' '}
              <OptionContainer>
                {topicAnswerUuidsTranslate.map((topicAnswer: string) => {
                  return <OptionName key={topicAnswer}>{topicAnswer}</OptionName>;
                })}
              </OptionContainer>
            </>
          );
        }
        return {
          changelog: {
            text: changelog,
            mode: 1,
          },
          changedBy: preference.userId,
          date: transformDate(preference.updatedAt),
        };
      });
      setHistory(parsedHistory);
    }
  };

  const onDelete = async () => {
    try {
      await CustomerService.deletePreferenceById(preference.uuid);
      message.success('Data has been successfully deleted.');
      switchConfirmDeletion(false);
      onClose();
    } catch (error) {
      showErrorResponseNotification(error);
    }
  };

  const onExportData = async () => {
    setIsExporting(true);

    try {
      const response = await CustomerService.getWidgetExportDataByUserId(preference.userId, {
        widgetId: preference.widgetUuid,
      });
      exportToCsv(response.data, `pmp_user_data_${preference.widgetUuid}_${Date.now()}.csv`);
      // Is asking for defining for any
    } catch (error: any) {
      if (error.response.status === 422) {
        message.error('There is no preference data for this widget');
      } else {
        showErrorResponseNotification(error);
      }
    } finally {
      setIsExporting(false);
    }
  };

  useEffect(() => {
    fetchWidget();
  }, []);

  useEffect(() => {
    const dataSource = preference.answers.map((d: Answer) => {
      const selectedTopic = widget?.topics.find((topic) => `${topic.uuid}:name` === d.topicName);
      return {
        topic: selectedTopic?.name || d.topicName,
        preferences: d.topicAnswerUuids
          ?.map((topicAnswerUuid: string) => {
            return (
              selectedTopic?.options.find((option) => `${option.uuid}:name` === topicAnswerUuid)
                ?.name || topicAnswerUuid
            );
          })
          .join('|uniqSplitter| '),
      };
    });

    const loadData = async () => {
      if (!widget) return;
      await fetchHistory();
      setUserData(dataSource);
    };
    loadData();
  }, [widget]);

  return (
    <Modal
      visible
      title={`Details [${preference.userId}]`}
      width={770}
      bodyHeight={500}
      isConfirmation
      hideFooterBorder
      onClose={onClose}
    >
      <TitleWrapper>
        <Title>{widget?.title}</Title>
        <Description>
          Last updated: {transformDate(preference.updatedAt)} <Dot /> Expiration date:{' '}
          {history[0]?.changelog.mode === ChangeModeValues.EXPIRED
            ? history[0].date
            : preference.expiresAt
              ? transformDate(preference.expiresAt)
              : 'Not defined'}{' '}
          {history[0]?.changelog.mode === ChangeModeValues.EXPIRED && (
            <ExpiredLabel>Expired</ExpiredLabel>
          )}
        </Description>
      </TitleWrapper>
      <StyledCollapse
        defaultActiveKey={['1']}
        data-cy="preference-data-modal-active-preferences-collapse"
      >
        <Panel header="Active Preferences" key="1">
          <StyledTable
            autoHeight
            pagination={false}
            noBorder
            dataSource={userData}
            columns={columns}
          />
        </Panel>
      </StyledCollapse>

      <StyledCollapse
        defaultActiveKey={['3']}
        style={{ marginTop: '20px' }}
        accordion
        data-cy="preference-data-modal-preference-history-collapse"
      >
        <Panel header="Preference History" key="2">
          <StyledTable
            autoHeight
            pagination={{
              position: ['bottomRight'],
              pageSize: DEFAULT_PAGINATION_TOTAL,
              style: { paddingRight: '16px' },
              showSizeChanger: false,
            }}
            noBorder
            dataSource={history}
            columns={historyColumns}
          />
        </Panel>
      </StyledCollapse>
      <DeletionBox>
        <span>Other Actions</span>
        <ButtonsContainer>
          <Button
            type="border"
            onClick={() => onExportData()}
            data-cy="preference-data-modal-export-button"
            disabled={isExporting}
          >
            <ExportIcon />
            CSV Export
          </Button>
          <Button
            type="border"
            onClick={() => switchConfirmDeletion(true)}
            data-cy="preference-data-modal-delete-button"
          >
            <TrashCanIcon />
            Delete
          </Button>
        </ButtonsContainer>
      </DeletionBox>

      {confirmDeletion && (
        <DeleteConfirmationModal
          onDelete={onDelete}
          onClose={() => switchConfirmDeletion(false)}
          primaryButtonLabel="Delete"
          primaryButtonDanger
          width={480}
          hideFooterBorder={false}
          isDefaultFooter={false}
          title="Preference Data Deletion"
          text="Are you sure you want to delete all preference data captured for this user?"
        />
      )}
    </Modal>
  );
};

export default UserDetailsModal;
