export interface Theme {
  colors: {
    primary: string;
    green: string;
    white: string;
    blue500: string;
    blackHex: string;
    background: string;
    primaryText: string;
    primaryIcons: string;
    greyIcons: string;
    defaultIcons: string;
    disabledButton: string;
    red: string;
    gray: string;
    darkGray: string;
    darkerGray: string;
    silver: string;
    border: string;
    highlightBlue: string;
    grayBlue300: string;
    grayBlue100: string;
    lightBlue: string;
    lightBlue200: string;
    lightBorder: string;
    lightBackgroundBlue: string;
    lightDark: string;
    infoBlue: (a?: number) => string;
    black: (a?: number) => string;
  };
  tooltipShadow: string;
  tableShadow: string;
}

export const theme: Theme = {
  // TODO: fix namings for colors, because some of them named rly weird, like defaultIcons which is used not only for icons
  colors: {
    primary: '#0045A5',
    green: '#17855E',
    white: '#FFFFFF',
    blue500: '#7FA2D2',
    blackHex: '#000000',
    background: '#FAFAFA',
    primaryText: '#303030',
    primaryIcons: '#595959',
    greyIcons: '#8E8E8E',
    defaultIcons: '#CECECE',
    disabledButton: '#E4E4E4',
    red: '#DD2E44',
    gray: '#DEDEDE',
    darkGray: '#F7F7F7',
    darkerGray: '#EBEDEF',
    silver: '#979797',
    border: '#EAEAEA',
    highlightBlue: '#4074BC',
    grayBlue300: '#B3B8DB',
    grayBlue100: '#EAECF5',
    lightBackgroundBlue: '#F5F8FC',
    lightBlue: '#D6E1F0',
    lightBlue200: '#EBF1F8',
    lightBorder: '#F2F6FD',
    lightDark: '#021836',
    infoBlue: (a = 1) => `rgba(0, 150, 255, ${a})`,
    black: (a = 1) => `rgba(0, 0, 0, ${a})`,
  },
  tooltipShadow:
    '0 1px 10px 0 rgba(0,0,0,0.12), 0 4px 5px 0 rgba(0,0,0,0.14), 0 2px 4px -1px rgba(0,0,0,0.2)',
  tableShadow:
    '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0px rgba(0,0,0,0.2)',
};

export default theme;
