import { AxiosResponse } from 'axios';
import { IStyles } from 'lib/interfaces/styles';
import { AbstractApiService } from './abstractApiService';
import { Axios } from './axiosInstance';
import { ILocalization } from '../interfaces/widget';

export interface Options {
  name: string;
  order: number;
  uuid: string;
  default: boolean;
}

export interface Topic {
  name: string;
  description: string;
  required: boolean;
  options: Options[];
  order: number;
  selectType: string;
  uuid: string;
}

export interface Widgets {
  createdAt: string;
  description: ILocalization | string;
  additionalNote: ILocalization | string;
  hidden: boolean;
  doubleOptIn: boolean;
  title: ILocalization | string;
  topics: Topic[];
  updatedAt: string;
  uuid: string;
  language?: string;
  _id: string;
}

export enum PacType {
  FULL = 'full',
  LITE = 'lite',
}

export interface Pac {
  _id?: string;
  creatorId: string;
  customerIds: string[];
  description: string;
  additionalNote?: string;
  styles: IStyles;
  title: string;
  uuid: string;
  widgetUuids: string[];
  widgets: Widgets[];
  isEmailLoginAvailable: boolean;
  editableLanguages: string[];
  visibleLanguages: string[];
  language?: string;
  deactivated?: boolean;
  active?: boolean;
  type: PacType;
}

export interface NewPac {
  title: string;
  description?: string | null;
  customerId?: string;
  sendNotification?: boolean;
}

export interface NewPacLite {
  title: string;
  description?: string;
  logoUrl?: string;
  widgetTitle: string;
  widgetDescription?: string;
  doubleOptIn: boolean;
  baseColor: string;
  formDataCollectorInfo?: {
    formSelector: string;
    userIdSelector: string;
    consentSelector: string;
  };
}

export interface CreatePacLiteResponse {
  pacUuid: string;
  widgetUuid: string;
  optionUuid: string;
  publicApiKey: string;
}

interface searchParams {
  short?: boolean;
  page?: number;
  limit?: number;
  search?: string;
}
export interface Translation {
  language?: string;
  title?: string;
  description?: string;
}

interface PacConfig {
  title: string;
  description: string;
  isEmailLoginAvailable: boolean;
  editableLanguages: string[];
  visibleLanguages: string[];
}

class PacService extends AbstractApiService {
  create(newPac: NewPac): Promise<AxiosResponse<Pac>> {
    return Axios.post('pacs/default', newPac, this.getApiHeaders());
  }

  createPacLite(NewPacLite: NewPacLite): Promise<AxiosResponse<CreatePacLiteResponse>> {
    return Axios.post('pacs/lite', NewPacLite, this.getApiHeaders());
  }

  get(
    params: searchParams = {},
  ): Promise<AxiosResponse<{ results: Pac[]; currentPage: number; count: number }>> {
    return Axios.get('pacs', { ...this.getApiHeaders(), params });
  }

  getById(pacUuid: string): Promise<AxiosResponse<Pac>> {
    return Axios.get(`widgets/pac/${pacUuid}`, this.getApiHeaders());
  }

  delete(pacUuid: string): Promise<boolean> {
    return Axios.delete(`pacs/${pacUuid}`, this.getApiHeaders());
  }

  deactivate(pacUuid: string): Promise<boolean> {
    return Axios.patch(`pacs/default/${pacUuid}/deactivate`, {}, this.getApiHeaders());
  }

  activate(pacUuid: string): Promise<boolean> {
    return Axios.patch(`pacs/default/${pacUuid}/activate`, {}, this.getApiHeaders());
  }

  switchPacVersion(pacUuid: string, type: PacType): Promise<boolean> {
    return Axios.patch(`pacs/default/${pacUuid}/type`, { type }, this.getApiHeaders());
  }

  update(pacUuid: string, pacConfig: PacConfig): Promise<AxiosResponse<Pac>> {
    return Axios.patch(`pacs/default/${pacUuid}`, pacConfig, this.getApiHeaders());
  }

  updateWidgetFromPack(
    pacUuid: string,
    widgetConfig: { widgets: string[] },
  ): Promise<AxiosResponse<Widgets[]>> {
    return Axios.patch(`pacs/${pacUuid}/order/widgets`, widgetConfig, this.getApiHeaders());
  }

  getTranslationLanguages(pacUuid: string, language: string): Promise<AxiosResponse<Pac>> {
    return Axios.get(`pac-translations/${pacUuid}/${language}`, this.getApiHeaders());
  }

  postTranslation(pacUuid: string, translation: Translation): Promise<AxiosResponse<Pac>> {
    return Axios.post(`pac-translations/${pacUuid}`, translation, this.getApiHeaders());
  }

  updateTranslation(
    translationsUuid: string,
    translation: Translation,
  ): Promise<AxiosResponse<Pac>> {
    return Axios.patch(`pac-translations/${translationsUuid}`, translation, this.getApiHeaders());
  }

  updateOwner(ownerEmail: string, pacUuid: string): Promise<AxiosResponse<Pac>> {
    return Axios.patch(
      `/pacs/customer/owner/${pacUuid}`,
      { newOwner: ownerEmail },
      this.getApiHeaders(),
    );
  }

  addUser(
    pacUuid: string,
    newCustomer: {
      newCustomerId: string;
      notifyViaEmail?: boolean;
    },
  ): Promise<AxiosResponse<Pac>> {
    return Axios.patch(`pacs/customer/${pacUuid}`, newCustomer, this.getApiHeaders());
  }

  deleteUser(pacUuid: string, customerId: string): Promise<AxiosResponse<Pac>> {
    return Axios.delete(`pacs/customer/${pacUuid}`, {
      ...this.getApiHeaders({
        'x-customer-id': customerId,
      }),
    });
  }
}

export default new PacService();
