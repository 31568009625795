import React, { useEffect, useState } from 'react';
import { Form } from 'antd';

import { useMessage } from 'lib/hooks';
import { Fonts, TitleFontSize, WidgetFontSize } from 'lib/enums/styling';
import { hexCode } from 'lib/regex/validations';
import StylingService from 'lib/api/stylingApi';
import { IStyles } from 'lib/interfaces/styles';

import { Item, Input, InputNumber, Tooltip } from 'components/Form';
import Switch from 'components/Switch/Switch';
import { StyledSelect as Select } from 'components/Select/Select';
import { Pac } from 'lib/api/pacApi';
import { showErrorResponseNotification } from 'lib/helpers';

import { theme } from 'lib/theme';
import SidebarButtons from '../SidebarButtons';

import {
  Container,
  SectionWrapper,
  SectionTitle,
  CustomLabel,
  FlexWrapper,
  FlexChild,
  StyledColorPicker,
} from './styled';

interface IProps {
  token: string;
  pacId: string;
  pacDetails?: Pac;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
}

const initialStylingValues = {
  uuid: '',
  imageUrl: '',
  imageAltText: '',
  imageActionUrl: '',
  pacFontFamily:
    'BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
  pacFontSize: 32,
  pacFontColor: '#FFFFFF',
  pacHeaderBackgroundColor: theme.colors.primary,
  pacBodyBackgroundColor: '#FFFFFF',
  widgetFontFamily:
    'BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
  widgetFontSize: 16,
  widgetTextColor: '#303030',
  widgetBorderColor: '#DEDEDE',
  widgetBorderShape: 12,
  widgetBorderSizeButtons: 6,
  widgetBackgroundShadow: false,
  widgetBackgroundColor: '#FFFFFF',
  widgetCancelButtonBackgroundColor: '#EFEFEF',
  widgetCancelButtonTextColor: '#303030',
  widgetSubmitButtonBackgroundColor: theme.colors.primary,
  widgetSubmitButtonTextColor: '#FFFFFF',
  createdAt: '',
  updatedAt: '',
};

const StylingTab = ({ token, pacId, pacDetails, setHasUnsavedChanges }: IProps) => {
  const [stylingForm] = Form.useForm();
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(true);
  const message = useMessage();
  const [styles, setStyles] = useState<IStyles | null>(null);

  const fetchStylingForPac = async () => {
    try {
      const response = await StylingService.get(pacId);
      setStyles(response.data);
      stylingForm.setFieldsValue(response.data);
    } catch (error) {
      showErrorResponseNotification(error);
    }
  };

  const handleSave = async () => {
    const data = await stylingForm.validateFields();
    if (styles) {
      try {
        const response = await StylingService.update(pacId, data);
        setStyles(response.data);
        setIsSaveButtonDisabled(true);
        return message.success('Styles for configuration successfully updated!');
      } catch (error) {
        return showErrorResponseNotification(error);
      }
    }

    try {
      const response = await StylingService.post(pacId, data);
      setStyles(response.data);
      setIsSaveButtonDisabled(true);
      return message.success('Styles for configuration successfully created!');
    } catch (error) {
      return showErrorResponseNotification(error);
    }
  };

  const onFieldsChanged = (_: any, allFields: any[]): void => {
    const currentStyles = styles || initialStylingValues;

    const foundChange = allFields.find((field: any) => {
      const fieldValue = field.value;
      return fieldValue !== currentStyles[field.name[0] as keyof IStyles];
    });

    if (foundChange) {
      setIsSaveButtonDisabled(false);
      return;
    }
    setIsSaveButtonDisabled(true);
  };

  useEffect(() => {
    if (!token) {
      return;
    }
    fetchStylingForPac();
  }, [token]);

  return (
    <Form
      form={stylingForm}
      initialValues={styles ?? initialStylingValues}
      onFieldsChange={onFieldsChanged}
    >
      <Container>
        <SectionWrapper borderBottom>
          <h2>Page Customization</h2>
          <span>Customize the styling of your Preference Management Center.</span>
        </SectionWrapper>

        <SectionWrapper borderBottom>
          <SectionTitle>
            Logo{' '}
            <Tooltip
              title="Define your custom logo to be displayed in your Preference Management Center page. The image to be scaled to 35 pixels height. You can also define the short description for alt-tag text and specify the URL address that user will be forwarded when clicking on the logo image."
              placement="right"
            />
          </SectionTitle>
          <CustomLabel>Logo source URL</CustomLabel>
          <Item
            required
            name="imageUrl"
            validateTrigger="onBlur"
            rules={[
              {
                type: 'url',
                message: 'Enter valid URL (e.g. http://www.domain.com)',
              },
            ]}
          >
            <Input placeholder="Enter the URL of your logo" data-cy="pac-styling-image-url-input" />
          </Item>
          <CustomLabel>Alt Tag</CustomLabel>
          <Item required name="imageAltText" validateTrigger="onBlur">
            <Input
              data-cy="pac-styling-alt-tag-input"
              placeholder="Enter alt text that will load up if logo is not available"
            />
          </Item>
          <CustomLabel>URL</CustomLabel>
          <Item
            required
            name="imageActionUrl"
            validateTrigger="onBlur"
            rules={[
              {
                type: 'url',
                message: 'Enter valid URL (e.g. http://www.domain.com)',
              },
            ]}
          >
            <Input
              data-cy="pac-styling-image-action-url-input"
              placeholder="Enter the URL where clicking on logo should lead to"
            />
          </Item>
        </SectionWrapper>

        <SectionWrapper borderBottom>
          <SectionTitle>
            Fonts{' '}
            <Tooltip
              title="Here you can specify the Font and size for the Preference Manager page title. The description text size will be taken as relative value from the title text size."
              placement="right"
            />
          </SectionTitle>
          <FlexWrapper>
            <FlexChild>
              <CustomLabel>Font-Family</CustomLabel>
              <Item required name="pacFontFamily" validateTrigger="onBlur">
                <Select
                  placeholder="System fonts (Default)"
                  data-cy="pac-styling-font-family-select"
                >
                  <Select.Option
                    key={Fonts.DEFAULT}
                    data-cy={`pac-styling-font-family-${Fonts.DEFAULT}-option`}
                    value="BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,Arial,sans-serif"
                  >
                    {Fonts.DEFAULT}
                  </Select.Option>
                  <Select.Option
                    key={Fonts.HELVETICA}
                    value={Fonts.HELVETICA}
                    data-cy={`pac-styling-font-family-${Fonts.HELVETICA}-option`}
                  >
                    {Fonts.HELVETICA}
                  </Select.Option>
                  <Select.Option
                    key={Fonts.VERDANA}
                    value={Fonts.VERDANA}
                    data-cy={`pac-styling-font-family-${Fonts.VERDANA}-option`}
                  >
                    {Fonts.VERDANA}
                  </Select.Option>
                  <Select.Option
                    key={Fonts.GEORGIA}
                    value={Fonts.GEORGIA}
                    data-cy={`pac-styling-font-family-${Fonts.GEORGIA}-option`}
                  >
                    {Fonts.GEORGIA}
                  </Select.Option>
                  <Select.Option
                    key={Fonts.ARIAL}
                    value={Fonts.ARIAL}
                    data-cy={`pac-styling-font-family-${Fonts.ARIAL}-option`}
                  >
                    {Fonts.ARIAL}
                  </Select.Option>
                </Select>
              </Item>
            </FlexChild>
            <FlexChild>
              <CustomLabel>Font Size (Title)</CustomLabel>
              <Item required name="pacFontSize" validateTrigger="onBlur">
                <Select
                  placeholder={`${TitleFontSize.S}px (Default)`}
                  data-cy="pac-styling-font-size-select"
                >
                  <Select.Option
                    key={TitleFontSize.XS}
                    value={TitleFontSize.XS}
                    data-cy={`pac-styling-font-size-${TitleFontSize.XS}-option`}
                  >
                    {TitleFontSize.XS}px
                  </Select.Option>
                  <Select.Option
                    key={TitleFontSize.S}
                    value={TitleFontSize.S}
                    data-cy={`pac-styling-font-size-${TitleFontSize.S}-option`}
                  >
                    {TitleFontSize.S}px (Default)
                  </Select.Option>
                  <Select.Option
                    key={TitleFontSize.M}
                    value={TitleFontSize.M}
                    data-cy={`pac-styling-font-size-${TitleFontSize.M}-option`}
                  >
                    {TitleFontSize.M}px
                  </Select.Option>
                  <Select.Option
                    key={TitleFontSize.L}
                    value={TitleFontSize.L}
                    data-cy={`pac-styling-font-size-${TitleFontSize.L}-option`}
                  >
                    {TitleFontSize.L}px
                  </Select.Option>
                  <Select.Option
                    key={TitleFontSize.XL}
                    value={TitleFontSize.XL}
                    data-cy={`pac-styling-font-size-${TitleFontSize.XL}-option`}
                  >
                    {TitleFontSize.XL}px
                  </Select.Option>
                </Select>
              </Item>
            </FlexChild>
          </FlexWrapper>
        </SectionWrapper>

        <SectionWrapper>
          <SectionTitle>
            Colors{' '}
            <Tooltip
              title="Here you can specify color palette for the Preference Management Center page - Background color will be used for the header  background, and text color would affect the color of the text and icon elements. "
              placement="right"
            />
          </SectionTitle>
          <FlexWrapper>
            <FlexChild>
              <CustomLabel>Header Background Color</CustomLabel>
              <Item
                rules={[hexCode]}
                required
                name="pacHeaderBackgroundColor"
                validateTrigger="onBlur"
                data-cy="pac-styling-page-header-background-color"
              >
                <StyledColorPicker
                  showText
                  disabledAlpha
                  onChange={(color) => {
                    stylingForm.setFieldValue('pacHeaderBackgroundColor', color.toHexString());
                    return color.toHexString();
                  }}
                />
              </Item>
            </FlexChild>
            <FlexChild>
              <CustomLabel>Header Text Color</CustomLabel>
              <Item rules={[hexCode]} required name="pacFontColor" validateTrigger="onBlur">
                <StyledColorPicker
                  showText
                  disabledAlpha
                  onChange={(color) => {
                    stylingForm.setFieldValue('pacFontColor', color.toHexString());
                    return color.toHexString();
                  }}
                />
              </Item>
            </FlexChild>
            <FlexChild>
              <CustomLabel>Page Background Color</CustomLabel>
              <Item
                data-cy="pac-styling-page-background-color"
                rules={[hexCode]}
                required
                name="pacBodyBackgroundColor"
                validateTrigger="onBlur"
              >
                <StyledColorPicker
                  showText
                  disabledAlpha
                  onChange={(color) => {
                    stylingForm.setFieldValue('pacBodyBackgroundColor', color.toHexString());
                    return color.toHexString();
                  }}
                />
              </Item>
            </FlexChild>
          </FlexWrapper>
        </SectionWrapper>
      </Container>

      <Container>
        <SectionWrapper borderBottom>
          <h2>Widget Customization</h2>
          <span>
            Customize the styling of all widgets displayed in your Preference Management Center.
          </span>
        </SectionWrapper>

        <SectionWrapper borderBottom>
          <SectionTitle>
            Fonts{' '}
            <Tooltip
              title="Specify the Font and size for the Widget title. The text size for all other widget text elements - description, widget topics and options will be taken as relative value from the Widget title text size."
              placement="right"
            />
          </SectionTitle>
          <FlexWrapper>
            <FlexChild>
              <CustomLabel>Font-Family</CustomLabel>
              <Item required name="widgetFontFamily" validateTrigger="onBlur">
                <Select
                  placeholder="System fonts (Default)"
                  data-cy="pac-styling-widget-font-family-select"
                >
                  <Select.Option
                    key={Fonts.DEFAULT}
                    data-cy={`pac-styling-widget-font-family-${Fonts.DEFAULT}-option`}
                    value="BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,Arial,sans-serif"
                  >
                    {Fonts.DEFAULT}
                  </Select.Option>
                  <Select.Option
                    key={Fonts.HELVETICA}
                    value={Fonts.HELVETICA}
                    data-cy={`pac-styling-widget-font-family-${Fonts.HELVETICA}-option`}
                  >
                    {Fonts.HELVETICA}
                  </Select.Option>
                  <Select.Option
                    key={Fonts.VERDANA}
                    value={Fonts.VERDANA}
                    data-cy={`pac-styling-widget-font-family-${Fonts.VERDANA}-option`}
                  >
                    {Fonts.VERDANA}
                  </Select.Option>
                  <Select.Option
                    key={Fonts.GEORGIA}
                    value={Fonts.GEORGIA}
                    data-cy={`pac-styling-widget-font-family-${Fonts.GEORGIA}-option`}
                  >
                    {Fonts.GEORGIA}
                  </Select.Option>
                  <Select.Option
                    key={Fonts.ARIAL}
                    value={Fonts.ARIAL}
                    data-cy={`pac-styling-widget-font-family-${Fonts.ARIAL}-option`}
                  >
                    {Fonts.ARIAL}
                  </Select.Option>
                </Select>
              </Item>
            </FlexChild>
            <FlexChild>
              <CustomLabel>Font Size (Title)</CustomLabel>
              <Item required name="widgetFontSize" validateTrigger="onBlur">
                <Select
                  placeholder={`${WidgetFontSize.S}px (Default)`}
                  data-cy="pac-styling-widget-font-size-select"
                >
                  <Select.Option
                    key={WidgetFontSize.XS}
                    value={WidgetFontSize.XS}
                    data-cy={`pac-styling-widget-font-size-${WidgetFontSize.XS}-option`}
                  >
                    {WidgetFontSize.XS}px
                  </Select.Option>
                  <Select.Option
                    key={WidgetFontSize.S}
                    value={WidgetFontSize.S}
                    data-cy={`pac-styling-widget-font-size-${WidgetFontSize.S}-option`}
                  >
                    {WidgetFontSize.S}px (Default)
                  </Select.Option>
                  <Select.Option
                    key={WidgetFontSize.M}
                    value={WidgetFontSize.M}
                    data-cy={`pac-styling-widget-font-size-${WidgetFontSize.M}-option`}
                  >
                    {WidgetFontSize.M}px
                  </Select.Option>
                  <Select.Option
                    key={WidgetFontSize.L}
                    value={WidgetFontSize.L}
                    data-cy={`pac-styling-widget-font-size-${WidgetFontSize.L}-option`}
                  >
                    {WidgetFontSize.L}px
                  </Select.Option>
                  <Select.Option
                    key={WidgetFontSize.XL}
                    value={WidgetFontSize.XL}
                    data-cy={`pac-styling-widget-font-size-${WidgetFontSize.XL}-option`}
                  >
                    {WidgetFontSize.XL}px
                  </Select.Option>
                </Select>
              </Item>
            </FlexChild>
          </FlexWrapper>
        </SectionWrapper>

        <SectionWrapper borderBottom>
          <SectionTitle>
            Colors{' '}
            <Tooltip
              title="The color settings would affect the color of the text elements in the widget, color of the active elements - highlight color, and the color of the border element in the widget design."
              placement="right"
            />
          </SectionTitle>
          <FlexWrapper>
            <FlexChild>
              <CustomLabel>
                Save Button (Background){' '}
                <Tooltip
                  title="The color settings to be applied also for highlight color schema of selection elements and links"
                  placement="right"
                />
              </CustomLabel>
              <Item
                rules={[hexCode]}
                required
                name="widgetSubmitButtonBackgroundColor"
                validateTrigger="onBlur"
                data-cy="pac-styling-submit-button-background-color"
              >
                <StyledColorPicker
                  showText
                  disabledAlpha
                  onChange={(color) => {
                    stylingForm.setFieldValue(
                      'widgetSubmitButtonBackgroundColor',
                      color.toHexString(),
                    );
                    return color.toHexString();
                  }}
                />
              </Item>
            </FlexChild>
            <FlexChild>
              <CustomLabel>Save Button (Text)</CustomLabel>
              <Item
                rules={[hexCode]}
                required
                name="widgetSubmitButtonTextColor"
                validateTrigger="onBlur"
                data-cy="pac-styling-submit-button-text-color"
              >
                <StyledColorPicker
                  showText
                  disabledAlpha
                  onChange={(color) => {
                    stylingForm.setFieldValue('widgetSubmitButtonTextColor', color.toHexString());
                    return color.toHexString();
                  }}
                />
              </Item>
            </FlexChild>
            <FlexChild>
              <CustomLabel>
                Cancel Button (Background){' '}
                <Tooltip
                  title="The color settings to be also applied for the background color of selection elements"
                  placement="right"
                />
              </CustomLabel>
              <Item
                rules={[hexCode]}
                required
                name="widgetCancelButtonBackgroundColor"
                validateTrigger="onBlur"
                data-cy="pac-styling-cancel-button-background-color"
              >
                <StyledColorPicker
                  showText
                  disabledAlpha
                  onChange={(color) => {
                    stylingForm.setFieldValue(
                      'widgetCancelButtonBackgroundColor',
                      color.toHexString(),
                    );
                    return color.toHexString();
                  }}
                />
              </Item>
            </FlexChild>
            <FlexChild>
              <CustomLabel>Cancel Button (Text)</CustomLabel>
              <Item
                rules={[hexCode]}
                required
                name="widgetCancelButtonTextColor"
                validateTrigger="onBlur"
                data-cy="pac-styling-cancel-button-text-color"
              >
                <StyledColorPicker
                  showText
                  disabledAlpha
                  onChange={(color) => {
                    stylingForm.setFieldValue('widgetCancelButtonTextColor', color.toHexString());
                    return color.toHexString();
                  }}
                />
              </Item>
            </FlexChild>
            <FlexChild>
              <CustomLabel>Background Color</CustomLabel>
              <Item
                rules={[hexCode]}
                required
                name="widgetBackgroundColor"
                validateTrigger="onBlur"
              >
                <StyledColorPicker
                  showText
                  disabledAlpha
                  onChange={(color) => {
                    stylingForm.setFieldValue('widgetBackgroundColor', color.toHexString());
                    return color.toHexString();
                  }}
                />
              </Item>
            </FlexChild>
            <FlexChild>
              <CustomLabel>Border Color</CustomLabel>
              <Item rules={[hexCode]} required name="widgetBorderColor" validateTrigger="onBlur">
                <StyledColorPicker
                  showText
                  disabledAlpha
                  onChange={(color) => {
                    stylingForm.setFieldValue('widgetBorderColor', color.toHexString());
                    return color.toHexString();
                  }}
                />
              </Item>
            </FlexChild>
            <FlexChild>
              <CustomLabel>Text Color</CustomLabel>
              <Item rules={[hexCode]} required name="widgetTextColor" validateTrigger="onBlur">
                <StyledColorPicker
                  showText
                  disabledAlpha
                  onChange={(color) => {
                    stylingForm.setFieldValue('widgetTextColor', color.toHexString());
                    return color.toHexString();
                  }}
                />
              </Item>
            </FlexChild>
          </FlexWrapper>
        </SectionWrapper>

        <SectionWrapper borderBottom>
          <SectionTitle>
            Shape{' '}
            <Tooltip
              title="Enter a value in pixel (px) to round off the buttons displayed in your Preference Management Center and the Widget borders. "
              placement="right"
            />
          </SectionTitle>
          <FlexWrapper>
            <FlexChild>
              <CustomLabel>Widget (rounded corners in px)</CustomLabel>
              <Item required name="widgetBorderShape" validateTrigger="onBlur">
                <InputNumber
                  type="number"
                  placeholder="Please enter a number "
                  data-cy="pac-styling-widget-border-shape-input"
                />
              </Item>
            </FlexChild>
            <FlexChild>
              <CustomLabel>Buttons (rounded corners in px)</CustomLabel>
              <Item required name="widgetBorderSizeButtons" validateTrigger="onBlur">
                <InputNumber
                  type="number"
                  placeholder="Please enter a number "
                  data-cy="pac-styling-widget-border-size-buttons-input"
                />
              </Item>
            </FlexChild>
          </FlexWrapper>
        </SectionWrapper>

        <SectionWrapper>
          <FlexWrapper>
            <FlexChild>
              <SectionTitle>
                Background Shadow{' '}
                <Tooltip
                  title="When checked, a box shadow is applied to your widgets to make them stand out more clearly from your Preference Management Center page. "
                  placement="right"
                />
              </SectionTitle>
            </FlexChild>
            <FlexChild style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <Item valuePropName="checked" name="widgetBackgroundShadow" noStyle>
                <Switch data-cy="pac-styling-widget-background-shadow-switch" />
              </Item>
            </FlexChild>
          </FlexWrapper>
        </SectionWrapper>
      </Container>

      <SidebarButtons
        setHasUnsavedChanges={setHasUnsavedChanges}
        onSave={handleSave}
        isSaveDisabled={isSaveButtonDisabled}
        isPacDeactivated={!!pacDetails?.deactivated}
      />
    </Form>
  );
};

export default StylingTab;
