import React, { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Checkbox, Typography } from 'antd';
import artBoardImage from 'assets/ArtBoard.svg';
import codeSample from 'assets/CodeSample.svg';
import googleConnector from 'assets/GoogleConnector.svg';
import Button from 'components/Button/Button';
import Logo from 'components/Logo';
import Spin from 'components/Spin';
import PacService, { Pac } from 'lib/api/pacApi';
import privateKeysService from 'lib/api/privateKeysApi';
import { AMPLITUDE_EVENTS } from 'lib/constants/amplitudeConfig';
import { AmplitudeContext } from 'lib/contexts/amplitudeContext';
import { ApiKeyType } from 'lib/enums/apiKey';
import { showErrorResponseNotification } from 'lib/helpers';
import { OnboardingStorageService } from 'lib/services/onboardingStorageService';
import { useNavigate } from 'react-router-dom';
import { theme } from 'lib/theme';
import { HeaderSection } from './HeaderSection';
import {
  DashedLine,
  ImageContainer,
  IntroContent,
  IntroPoints,
  IntroSubtext,
  OnboardingContent,
  OnboardingSidebarTitle,
  PointNumber,
  ScrollableContainer,
  Sidebar,
  StyledLogo,
  StyledText,
  TermsAndConditionContainer,
  TermsAndConditionMessageContainer,
  Wrapper,
} from './styled';

const { Title } = Typography;

const StepWelcome = () => {
  const { user } = useAuth0();
  const [isDataLoading, setLoading] = useState<boolean>(true);
  const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);
  const { trackEvent } = useContext(AmplitudeContext);

  const navigate = useNavigate();
  const checkPac = async () => {
    try {
      const response = await PacService.get({
        search: user?.email,
      });
      const pacsThatCustomerOwns = response.data.results;
      const pacLite = pacsThatCustomerOwns.find((el: Pac) => el.type === 'lite');

      if (pacLite) {
        try {
          const apiKeys = await privateKeysService.get();
          OnboardingStorageService.setOnboardingResults({
            widgetUuid: pacLite?.widgetUuids[0],
            pacUuid: pacLite.uuid,
            publicApiKey:
              apiKeys.data.apiKeys?.find((k) => k.type === ApiKeyType.PUBLIC)?.key || '',
            optionUuid: pacLite?.widgets[0]?.topics[0]?.options[0].uuid,
          });
          navigate('/onboarding/step-5');
        } catch (error) {
          showErrorResponseNotification(error);
        }
      }
    } catch (error) {
      showErrorResponseNotification(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    trackEvent(AMPLITUDE_EVENTS.SUCCESSFUL_ONBOARD_LOGIN, { userId: user?.email });
    localStorage.removeItem('returnTo');
    checkPac();
  }, []);

  const onGetStart = () => {
    OnboardingStorageService.setStep1Data({
      title: '',
      description: '',
      widgetTitle: '',
      widgetDescription: '',
      logoUrl: undefined,
      doubleOptIn: false,
      baseColor: theme.colors.primary,
    });
    navigate('/onboarding/step-2');
  };

  return (
    <Wrapper>
      <Sidebar>
        <StyledLogo>
          <Logo />
        </StyledLogo>
        <div>
          <StyledText>Preference Manager Audience Unlocker</StyledText>
          <OnboardingSidebarTitle level={1}>Welcome!</OnboardingSidebarTitle>
          <span>
            Preference Manager Audience Unlocker is a smart preference tool that simplifies consent
            management and enables seamless activation for retargeting via platforms like Google
            Customer Match.
          </span>
        </div>
      </Sidebar>
      {isDataLoading ? (
        <OnboardingContent>
          <Spin />
        </OnboardingContent>
      ) : (
        <OnboardingContent>
          <HeaderSection activeStepIndex={0} />
          <Title level={2}>Set up Audience Unlocker in 3 easy steps</Title>
          <ScrollableContainer>
            <div>
              <IntroPoints>
                <IntroContent>
                  <DashedLine />
                  <div>
                    <PointNumber>1</PointNumber>
                  </div>
                  <div>
                    <Title level={4}>Create your Preference Center</Title>
                    <IntroSubtext>
                      Provide your users with a one-stop place to access and update their privacy
                      preferences.
                    </IntroSubtext>
                  </div>
                  <ImageContainer>
                    <img
                      src={artBoardImage}
                      style={{ width: '230px' }}
                      alt="Create your Preference Center"
                    />
                  </ImageContainer>
                </IntroContent>
              </IntroPoints>
              <IntroPoints>
                <IntroContent>
                  <div>
                    <PointNumber>2</PointNumber>
                  </div>
                  <div>
                    <Title level={4}>Link to the existing web forms</Title>
                    <IntroSubtext>
                      Add a short script to your website’s code to capture users’ consent and send
                      their data directly to your chosen marketing platform.
                    </IntroSubtext>
                  </div>
                  <ImageContainer style={{ padding: 0 }}>
                    <img src={codeSample} alt="Link to the existing web forms" />
                  </ImageContainer>
                </IntroContent>
              </IntroPoints>
              <IntroPoints>
                <IntroContent>
                  <div>
                    <PointNumber>3</PointNumber>
                  </div>
                  <div>
                    <Title level={4}>Connect with Google Ads</Title>
                    <IntroSubtext>
                      Connect your Google Ads account and select your Customer List to automatically
                      save consented user data to that list.
                    </IntroSubtext>
                  </div>
                  <ImageContainer style={{ padding: 0 }}>
                    <img src={googleConnector} alt="Connect with Google Ads" />
                  </ImageContainer>
                </IntroContent>
              </IntroPoints>
            </div>
          </ScrollableContainer>
          <TermsAndConditionContainer>
            <TermsAndConditionMessageContainer>
              <Checkbox
                style={{ fontWeight: 400 }}
                onChange={(e) => setTermsAndConditions(e.target.checked)}
              >
                I hereby confirm that I have read and accept the{' '}
                <a
                  href="https://usercentrics.com/terms-and-conditions/?deviceId=449c87a0-1531-43f5-be9a-f99732c248d5"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>{' '}
                /{' '}
                <a
                  href="https://usercentrics.com/terms-and-conditions-usa/?deviceId=449c87a0-1531-43f5-be9a-f99732c248d5"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and Conditions (USA)
                </a>{' '}
                as well as the{' '}
                <a
                  href="https://usercentrics.com/wp-content/uploads/2023/08/Final_Joint-Product-DPA_UC.pdf?deviceId=449c87a0-1531-43f5-be9a-f99732c248d5"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data Processing Agreement
                </a>{' '}
                of the Usercentrics Consent Management Platform.
              </Checkbox>
              <Button
                type={!termsAndConditions ? 'default' : 'black'}
                size="large"
                htmlType="submit"
                data-cy="button-nextStep"
                style={{ alignSelf: 'center', whiteSpace: 'nowrap' }}
                disabled={!termsAndConditions}
                onClick={onGetStart}
              >
                Get started
              </Button>
            </TermsAndConditionMessageContainer>
          </TermsAndConditionContainer>
        </OnboardingContent>
      )}
    </Wrapper>
  );
};

export default StepWelcome;
