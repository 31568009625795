import { CreatePacLiteResponse } from 'lib/api/pacApi';
import { ONBOARDING_RESULTS, STEP_1_DATA, STEP_2_DATA } from 'lib/constants/onboarding';
import { IOnboardingStep1, IOnboardingStep2 } from 'lib/interfaces/onboarding';
import { theme } from 'lib/theme';

export class OnboardingStorageService {
  static setStep1Data(data: IOnboardingStep1) {
    sessionStorage.setItem(STEP_1_DATA, JSON.stringify(data));
  }

  static getStep1Data(): IOnboardingStep1 {
    const data = sessionStorage.getItem(STEP_1_DATA);
    return data
      ? JSON.parse(data)
      : {
          title: '',
          description: '',
          widgetTitle: '',
          widgetDescription: '',
          logoUrl: '',
          doubleOptIn: false,
          baseColor: theme.colors.primary,
        };
  }

  static setStep2Data(data: IOnboardingStep2) {
    sessionStorage.setItem(STEP_2_DATA, JSON.stringify(data));
  }

  static getStep2Data(): IOnboardingStep2 {
    const data = sessionStorage.getItem(STEP_2_DATA);
    return data
      ? JSON.parse(data)
      : {
          formSelector: '',
          userIdSelector: '',
          consentSelector: '',
        };
  }

  static setOnboardingResults(data: CreatePacLiteResponse) {
    sessionStorage.setItem(ONBOARDING_RESULTS, JSON.stringify(data));
  }

  static getOnboardingResults(): CreatePacLiteResponse {
    const data = sessionStorage.getItem(ONBOARDING_RESULTS);
    return data ? JSON.parse(data) : { pacUuid: '' };
  }
}
